<template>
  <div class="p-3 text-center">
    <div class="alert alert-danger" role="alert">
      Sorry!! Page Not found.
      <br />
      <a href="/" class="btn btn-sm btn-danger mt-2">Home</a>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
</style>  